@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin 1.8s linear infinite;
}

/* Now playing panel */

@media screen and (max-width: 767px) {
  .now-playing {
    max-height: 90vh;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
  }
  .now-playing-hidden {
    max-height: 0;
  }
  main {
    transition: all 0.3 ease-in-out;
  }
}
@media screen and (min-width: 768px) {
  .now-playing {
    position: fixed;
    height: 100vh;
    width: calc(20% + 200px);
    transition: width 0.3s ease-in-out;
  }
  .now-playing.now-playing-hidden {
    width: 0;
    visibility: hidden;
  }
  .now-playing + main {
    margin-left: calc(20% + 200px);
    transition: margin 0.3s ease-in-out;
  }
  .now-playing-hidden + main {
    margin-left: 0%;
  }
}

/* Record playing animation */

.album-item {
  position: relative;
  overflow: hidden;
}

.cover-image {
  width: 100%;
  transition: transform 0.7s ease-in-out;
}

.record {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  z-index: -1;
}

.is-playing .cover-image {
  transform: translateX(-100%);
}

.is-playing .record {
  animation: spin 1.8s linear infinite;
}
